import {Component} from '@angular/core';

import {Platform} from '@ionic/angular';
import {NavigationEnd, Router} from "@angular/router";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {environment} from "../environments/environment";
import {CustomerService} from "./providers/customer/customer.service";
import {FbPixelService} from "./providers/fb-pixel/fb-pixel.service";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private fbPixel: FbPixelService,
        private gtmService: GoogleTagManagerService,
        private customerSvc: CustomerService,
        private router: Router,
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.gtmService.pushTag(['js', new Date()]);
        this.gtmService.pushTag([
            'config',
            environment.gtag_ua,
            {user_id: this.customerSvc.getCustomer().getPSID()}
        ]);

        this.platform.ready().then(() => {
            this.router.events.forEach(item => {
                if (item instanceof NavigationEnd) {
                    const gtmTag = {
                        event: 'page',
                        pageName: item.url
                    };
                    this.gtmService.pushTag(gtmTag);
                    this.fbPixel.track('PageView');
                }
            });
        });
    }
}
