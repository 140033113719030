import {APP_INITIALIZER, ErrorHandler, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {Router, RouteReuseStrategy} from '@angular/router';

import {AlertController, IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ApiService} from "./providers/api/api.service";
import {CustomerService} from "./providers/customer/customer.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FacebookModule, FacebookService, InitParams} from "@jemys89/ngx-facebook";
import {environment} from "../environments/environment";
import {NgxStripeModule} from '@nomadreservations/ngx-stripe';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxIonicImageViewerModule} from "ngx-ionic-image-viewer";
import {AuthHelpers} from "./providers/AuthHelpers";

import * as Sentry from "@sentry/browser";
import {SentryErrorHandler} from "./SentryErrorHandler";

Sentry.init({
    dsn: "https://8a36dfbc78644cfa8e7cf0429e08b69c@o159499.ingest.sentry.io/5340805",
    // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
    // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
    // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
    integrations: [new Sentry.Integrations.TryCatch({
        XMLHttpRequest: false,
    })],
});

export function initApp(customerSvc: CustomerService, router: Router, fb: FacebookService, alertCtrl: AlertController) {
    return async () => {
        const urlParams = new URLSearchParams(window.location.search);
        try {
            customerSvc.setParams(urlParams);
        } catch (e) {
            const alert = await alertCtrl.create({
                message: 'Sessione scaduta',
                header: 'Scrivi "inizia" per avviare un nuovo ordine!',
                buttons: [
                    {
                        text: 'Ok',
                        handler: () => {
                            window.MessengerExtensions.requestCloseBrowser(async () => {

                            }, async () => {

                            });
                        }
                    }
                ]
            });
            await alert.present();
            return Promise.reject('Missing token');
        }
        let initParams: InitParams = {
            appId: environment.fb_app_id,
            xfbml: false,
            version: 'v3.0'
        };
        return fb.init(initParams);
    }
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        HttpClientModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule.forRoot({
            backButtonText: '',
        }),
        AppRoutingModule,
        FacebookModule.forRoot(),
        NgxStripeModule.forRoot(environment.stripe_pk),
        NgxIonicImageViewerModule
    ],
    providers: [
        {
            provide: ErrorHandler, useClass: SentryErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: AuthHelpers, multi: true, deps: [CustomerService]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [CustomerService, Router, FacebookService, AlertController],
            multi: true
        },
        {provide: 'googleTagManagerId', useValue: environment.gtag},
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        ApiService,
        CustomerService
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
}
