import {Injectable} from '@angular/core';
import {Customer} from "../../models/Customer";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    private customer: Customer;

    constructor() {
        this.customer = new Customer();
    }

    setParams(queryParamMap: URLSearchParams) {
        let token: string;
        if (!queryParamMap.has('token') && !environment.production) {
            token = window.sessionStorage.getItem('token');
        } else {
            token = queryParamMap.get('token');
            if (!environment.production) {
                window.sessionStorage.setItem('token', token);
            }
        }
        this.customer.initFromToken(token);
        queryParamMap.delete('token')
        queryParamMap.delete('t')
        //magic trick to hide token and other query string params
        history.pushState(null, "", location.href.split("?")[0] + '?' + queryParamMap.toString());
    }

    public getCustomer(): Customer {
        return this.customer;
    }
}
