import * as jwt_decode from 'jwt-decode';
import {JwtPayload} from "./JwtPayload";

export class Customer {
    private token?: string;
    private psid?: number
    private customer_id?: number;
    private cart_id?: number;

    public getPSID(): number {
        return this.psid;
    }

    public getCustomerId(): number {
        return this.customer_id;
    }

    initFromToken(token: string): void {
        this.token = token;
        const jwtPayload: JwtPayload = jwt_decode(token);
        this.psid = jwtPayload.psid;
        this.customer_id = jwtPayload.customer_id;
        this.cart_id = jwtPayload.cart_id;
    }

    getJWT(): string {
        return this.token;
    }
}
